<template>
    <div class="page2019">
        <div class="item">
          <h2 class="sub-title">完美谢幕·明星祝福</h2>
          <tx-video :options = "starVideoOptions"></tx-video>
        </div>
        <div class="item">
          <h2 class="sub-title">完美谢幕·精彩视频</h2>
          <tx-video :options = "endVideoOptions"></tx-video>
        </div>
        <div class="item">
          <h2 class="sub-title">现场照片</h2>
          <div class="pic-warp">
            <swiper class="swiper" :options="swiperOption">
                <swiper-slide v-for="(item, key) in picList" :key = key>
                  <ul v-lazy-container="{ selector: 'img', loading: '//txwl.internetbaidu.com/mobileTXW_3.0/exhibition/waterLoading.gif' }">
                    <li v-for="(list, index) in item" :key = index @click="showBig(item, index)">
                      <img :data-src="list" alt="">
                    </li>
                  </ul>
                </swiper-slide>
                <div class="swiper-pagination" slot="pagination"></div>
              </swiper>
          </div>
        </div>
        <div class="item">
          <h2 class="sub-title">展会介绍</h2>
          <div class="introduction">
            <div class="sub-title">
              <h2><span>PAGE</span><span>亚博会</span></h2>
              <p>亞洲市場最大的遊戲展覽</p>
            </div>
            <p class="text">
              亚博会（PAGE）致力于打造一个行业内具有公信力、公正和透明的平台。它是个综合性活动，包括贸易展览、产品发佈会与场地活动等。我们希望透过亚博会这些多元化的活动引导业者锦绣前程、在业界更自信，更健康发展。
            </p>
            <p class="text">
              与此同时，亚博会希望能成为全亚洲娱乐行业的枢纽，以及您不可错过的盛大活动之一。亚博会是让您找到适合您业务的新联繫、趋势产品以及解决方案的一站式学习中心。
            </p>
            <img class="img" src="//txwl.internetbaidu.com/mobileTXW_3.0/exhibition/img.jpg" alt="">
          </div>
        </div>
        <tx-img :imgIndex = currentIndex :list = imgList @closeImg = closeTxImg v-if="show"></tx-img>
    </div>
</template>
<script>
import mixin from './mixin'
export default {
  name: "page2019",
  data () {
    return {
      starVideoOptions: {
        bg: '//txwl.internetbaidu.com/mobileTXW_3.0/exhibition/video_bg.jpg',
        poster: '//txwl.internetbaidu.com/mobileTXW_3.0/exhibition/2019_mxzf_videoBg.jpg',
        src: '//txwl.internetbaidu.com/txws/new_images_one/zhzx/video/2019_mxzf.mp4'
      },
      endVideoOptions: {
        bg: '//txwl.internetbaidu.com/mobileTXW_3.0/exhibition/video_bg.jpg',
        poster: '//txwl.internetbaidu.com/mobileTXW_3.0/exhibition/2019_page_videoBg.jpg',
        src: '//txwl.internetbaidu.com/txws/new_images_one/zhzx/video/2019_page.mp4'
      },
      swiperOption: {
          pagination: {
            el: '.swiper-pagination'
          }
      },
      picList: [
        [
          '//txwl.internetbaidu.com/mobileTXW_3.0/exhibition/photo/page_pic_001.jpg',
          '//txwl.internetbaidu.com/mobileTXW_3.0/exhibition/photo/page_pic_002.jpg',
          '//txwl.internetbaidu.com/mobileTXW_3.0/exhibition/photo/page_pic_003.jpg',
          '//txwl.internetbaidu.com/mobileTXW_3.0/exhibition/photo/page_pic_004.jpg',
          '//txwl.internetbaidu.com/mobileTXW_3.0/exhibition/photo/page_pic_005.jpg',
          '//txwl.internetbaidu.com/mobileTXW_3.0/exhibition/photo/page_pic_006.jpg',
          '//txwl.internetbaidu.com/mobileTXW_3.0/exhibition/photo/page_pic_007.jpg',
          '//txwl.internetbaidu.com/mobileTXW_3.0/exhibition/photo/page_pic_008.jpg',
          '//txwl.internetbaidu.com/mobileTXW_3.0/exhibition/photo/page_pic_009.jpg',
        ],
        [
          '//txwl.internetbaidu.com/mobileTXW_3.0/exhibition/photo/page_pic_010.jpg',
          '//txwl.internetbaidu.com/mobileTXW_3.0/exhibition/photo/page_pic_011.jpg',
          '//txwl.internetbaidu.com/mobileTXW_3.0/exhibition/photo/page_pic_012.jpg',
          '//txwl.internetbaidu.com/mobileTXW_3.0/exhibition/photo/page_pic_013.jpg',
          '//txwl.internetbaidu.com/mobileTXW_3.0/exhibition/photo/page_pic_014.jpg',
          '//txwl.internetbaidu.com/mobileTXW_3.0/exhibition/photo/page_pic_015.jpg',
          '//txwl.internetbaidu.com/mobileTXW_3.0/exhibition/photo/page_pic_016.jpg',
          '//txwl.internetbaidu.com/mobileTXW_3.0/exhibition/photo/page_pic_017.jpg',
          '//txwl.internetbaidu.com/mobileTXW_3.0/exhibition/photo/page_pic_018.jpg',
        ],
        [
          '//txwl.internetbaidu.com/mobileTXW_3.0/exhibition/photo/page_pic_019.jpg',
          '//txwl.internetbaidu.com/mobileTXW_3.0/exhibition/photo/page_pic_020.jpg',
          '//txwl.internetbaidu.com/mobileTXW_3.0/exhibition/photo/page_pic_021.jpg',
          '//txwl.internetbaidu.com/mobileTXW_3.0/exhibition/photo/page_pic_022.jpg',
          '//txwl.internetbaidu.com/mobileTXW_3.0/exhibition/photo/page_pic_023.jpg',
          '//txwl.internetbaidu.com/mobileTXW_3.0/exhibition/photo/page_pic_024.jpg',
          '//txwl.internetbaidu.com/mobileTXW_3.0/exhibition/photo/page_pic_025.jpg',
          '//txwl.internetbaidu.com/mobileTXW_3.0/exhibition/photo/page_pic_026.jpg',
          '//txwl.internetbaidu.com/mobileTXW_3.0/exhibition/photo/page_pic_027.jpg'
        ]
      ]
    };
  },
  mixins: [mixin]
}
</script>
<style lang="less">
.page2019 {
  .item {
    margin-bottom: 50px;
    .pic-warp {
      width: 100%;
      .swiper-pagination-bullets {
        bottom: 0;
        .swiper-pagination-bullet {
            width: 30px;
            height: 30px;
            background: rgba(255, 255, 255, 0.8);
            &.swiper-pagination-bullet-active {
                background: #B11D22;
            }
        }
      }
      .swiper-slide {
        height: 0;
        &.swiper-slide-active {
          height: auto;
        }
        ul {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          margin-bottom: 60px;
          li {
            margin: 0 10px 10px 0;
            &:nth-child(3n) {
              margin-right: 0;
            }
            img {
              display: block;
              width: 368px;
              height: 210px;
            }
          }
        }
      }
    }
    .introduction {
      padding: 0 45px;
      .sub-title {
        h2 {
          display: flex;
          align-items: center;
          font-size: 78px;
          font-weight: bold;
          line-height: 100px;
          span:first-child {
            margin-right: 80px;
            position: relative;
            &:after {
              position: absolute;
              content: '';
              width: 8px;
              height: 70px;
              background: #fff;
              top: 12px;
              right: -46px;
            }
          }
        }
        & > p {
          font-size: 38px;
          font-weight: bold;
          background: linear-gradient(to right, #960045, #f83725);
          background-clip: text;
          -webkit-background-clip: text;
          color: transparent;
          line-height: 70px;
        }
      }
      p.text {
        font-size: 42px;
        line-height: 60px;
        text-align: justify;
        margin: 20px 0 50px;
      }
      img.img {
        display: block;
        width: 100%;
        height: auto;
      }
    }
  }
}
</style>