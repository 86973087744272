export default {
    data: function() {
        return {
            show: false,
            imgList: [],
            currentIndex: ''
        }
    },
    methods: {
        showBig(item, index) {
            this.show = true;
            this.imgList = item;
            this.currentIndex = index;
          },
          closeTxImg() {
            this.show = false;
          }
    }
}