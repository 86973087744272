<template>
  <div class="exhibition">
    <div class="exhibition-warp" :style="{backgroundImage: `url(${tabs[currentTab].bg})`}">
      <div class="tabs">
        <div class="title">展会资讯</div>
        <div class="tab">
          <span
            v-for="(item, key) in tabs"
            :key="key"
            :class="{'active': currentTab === key}"
            @click="changeTab(key)"
          >{{item.tab}}</span>
        </div>
      </div>
      <div class="title-logo">
        <h2>{{tabs[currentTab].title}}</h2>
        <img :src="tabs[currentTab].logo" alt />
      </div>
      <div class="exhibition-content">
      <!--   <page2020 v-if="currentTab === 0"></page2020> -->
        <page2019 v-if="currentTab === 0"></page2019>
        <g2e2019 v-if="currentTab === 1"></g2e2019>
      </div>
      <footer-copy></footer-copy>
    </div>
  </div>
</template>
<script>
/* import page2020 from './page2020' */
import page2019 from './page2019'
import g2e2019 from './g2e2019'
export default {
  name: "exhibition",
  data() {
    return {
      currentTab: 0,
      tabs: [
        /* {
          tab: "2020 PAGE",
          title: "2020菲律宾亚洲博览会",
          logo: "//txwl.internetbaidu.com/mobileTXW_3.0/exhibition/logo2020_page.png",
          bg: "//txwl.internetbaidu.com/mobileTXW_3.0/exhibition/bg1.jpg"
        }, */
        {
          tab: "2019 PAGE",
          title: "2019菲律宾亚洲博览会",
          logo: "//txwl.internetbaidu.com/mobileTXW_3.0/exhibition/logo2019_page.png",
          bg: "//txwl.internetbaidu.com/mobileTXW_3.0/exhibition/bg2.jpg"
        },
        {
          tab: "2019 G2E",
          title: "2019亞洲國際娛樂展",
          logo: "//txwl.internetbaidu.com/mobileTXW_3.0/exhibition/logo2019_g2e.png",
          bg: "//txwl.internetbaidu.com/mobileTXW_3.0/exhibition/bg3.jpg"
        }
      ]
    };
  },
  mounted() {
    this.utils.animateCSS( ".exhibition-warp .tabs", ["animate__backInLeft"]);
    this.changeTabAnimation()
  },
  methods: {
    changeTabAnimation() {
      this.utils.animateCSS( ".exhibition-warp .title-logo", ["animate__flipInX"]);
      this.utils.animateCSS( ".exhibition-warp .exhibition-content", ["animate__fadeIn", "animate__duration-1-5s"]);
    },
    changeTab(val) {
      console.log(val)
      this.currentTab = val
      this.changeTabAnimation()
    }
  },
  components: {
   /*  page2020, */
    page2019,
    g2e2019
  }
};
</script>
<style lang="less">
.exhibition {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  .exhibition-warp {
    height: auto;
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
  }
  .tabs {
    display: flex;
    width: 100%;
    align-items: center;
    height: 200px;
    border-bottom: 1px solid rgba(146, 145, 145, 0.1);
    padding: 0 45px;
    margin-bottom: 30px;
    .title {
      font-size: 60px;
      width: 300px;
      font-weight: bold;
    }
    .tab {
      display: flex;
      height: 100%;
      flex: 1;
      align-items: center;
      justify-content: space-around;
      span {
        width: 256px;
        height: 80px;
        line-height: 80px;
        text-align: center;
        font-size: 36px;
        color: #e4e4e1;
        opacity: 0.6;
        border-radius: 40px;
        &.active {
          background: linear-gradient(110deg, #e6006a 0%, #f83725 100%);
          color: #fff;
          opacity: 1;
        }
      }
    }
  }
  .title-logo {
    padding: 0 45px;
    h2 {
      font-size: 72px;
      font-weight: bold;
      background: linear-gradient(to right, #960045, #f83725);
      background-clip: text;
      -webkit-background-clip: text;
      color: transparent;
      line-height: 100px;
    }
    img {
      height: 106px;
      width: auto;
    }
  }
  .exhibition-content {
    padding: 30px 0;
    h2.sub-title {
      font-size: 48px;
      height: 120px;
      line-height: 130px;
      font-weight: bold;
      background-image: url('//txwl.internetbaidu.com/mobileTXW_3.0/exhibition/prefix.png');
      background-size: 12px 48px;
      background-position: left center;
      background-repeat: no-repeat;
      padding-left: 30px;
      margin: 0 45px;
    }
  }
}
</style>